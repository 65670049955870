body {
  margin: 0;
  font-family: "open-sans-regular", -apple-system, BlinkMacSystemFont,
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #3e4755;
}

html {
  scroll-behavior: smooth;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* Fonts */

@font-face {
  font-family: open-sans-regular;
  src: url("../fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: open-sans-light;
  src: url("../fonts/OpenSans-Light.ttf");
}

@font-face {
  font-family: open-sans-bold;
  src: url("../fonts/OpenSans-Bold.ttf");
}

@font-face {
  font-family: open-sans-semi;
  src: url("../fonts/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: poppins-medium;
  src: url("../fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: poppins-semi;
  src: url("../fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: poppins-bold;
  src: url("../fonts/Poppins-Bold.ttf");
}

/* Guide rules for dev */

.grid-container {
  /* border-left: 1px red solid;
  border-right: 1px red solid; */
}

/* Navigation */
.navigation {
  border-bottom: 5px solid #3e4755;
  position: fixed;
  background-color: #fff;
  z-index: 3;
  width: 100%;
}

h2 {
  font-family: poppins-semi;
  text-transform: uppercase;
  font-size: 2rem;
}

h5 {
  font-family: open-sans-semi;
}

hr {
  margin-top: 0;
}

section {
  background-color: #f5faff;
}

nav {
  font-family: poppins-semi;
  font-size: 1rem;
  text-transform: uppercase;
  height: 80px;
}

nav li {
  padding: 1.75rem 5px;
  cursor: pointer;
}

.hero {
  padding: calc(80px + 3.5rem) 0 3.5rem 0;
  font-size: 1.75rem;
  font-family: poppins-bold;
}

.hero p {
  padding-top: 3rem;
  padding-left: 1rem;
}

.avatar {
  position: relative;
}

.hero a {
  color: #ff685c;
}

.hero img {
  display: flex;
  margin: 0 auto;
  vertical-align: middle;
  max-width: 250px;
  border-radius: 50%;
  position: relative;
  z-index: 2;
}

.avatar .rectangle {
  background-color: #68ebc9;
  width: 145px;
  height: 140px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 1;
}

.skills {
  min-height: 200px;
  padding: 3rem 1rem 3rem 1rem;
  font-size: 1.25rem;
  font-weight: medium;
}

.skills ul {
  list-style-type: none;
  margin: 1.5rem 0 0 0;
}

.work {
  padding: 3rem 1rem 3rem 1rem;
}

.work a {
  color: #ff685c;
  font-family: poppins-semi;
}

.work .cell {
  margin: 1rem 0;
}

.work img {
  border-bottom: 1px solid #bcbec0;
  border-left: 1px solid #bcbec0;
  border-right: 1px solid #bcbec0;
}

.work .skill-tags {
  margin-bottom: 1rem;
}

.work .skill-tags span {
  background-color: #68ebc9;
  padding: 1px 5px;
  margin-right: 5px;
  color: #fff;
  font-family: poppins-medium;
}

.bar {
  background-color: #bcbec0;
  height: 22px;
  padding: 7px;
  border-radius: 5px 5px 0 0;
}

.bar > div {
  float: left;
  margin-left: 5px;
}

.green {
  background-color: #39d1b4;
  border-radius: 50%;
  height: 9px;
  width: 9px;
}

.yellow {
  background-color: #fff799;
  border-radius: 50%;
  height: 9px;
  width: 9px;
}

.red {
  background-color: #f65a5b;
  border-radius: 50%;
  height: 9px;
  width: 9px;
}

.education {
  padding: 3rem 1rem 3rem 1rem;
}

.experience a {
  color: #ff685c;
  font-family: poppins-semi;
}

.experience h5 {
  display: inline-block;
}

.experience span {
  padding: 0 0 10px 1rem;
  display: inline-block;
}

.experience .grid-x {
  margin-top: 1.5rem;
}

.experience ul {
  padding-left: 0.75rem;
}

.experience ul li {
  padding: 0.25rem 0;
}

/* Footer */

footer {
  background-color: #3e4755;
  color: #fff;
  margin: 0;
}

footer p {
  margin: 0;
  vertical-align: middle;
}

footer .grid-x {
  padding: 3rem 0 1.5rem 0;
}

footer ul {
  list-style-type: none;
}

footer ul li {
  display: inline;
  padding: 0 0 0 1rem;
  cursor: pointer;
}

footer a {
  color: #fff;
}

footer i {
  font-size: 2rem;
}

footer a:hover {
  color: #ff685c;
}

/* Small only */
@media screen and (max-width: 39.9375em) {
  .navigation {
    display: none;
  }

  .hero {
    padding: 1rem 0 3.5rem 0;
  }
}
